import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="464" height="10" viewBox="0 0 464 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M86.2648 10C76.6719 9.07169 6.42965 10.1081 1.32403 9.82831C-2.84423 9.45317 3.81701 2.84689 7.68611 1.87407C9.0423 1.52436 10.6179 1.47351 14.2277 1.63247C19.9516 1.93131 93.9632 1.33997 93.9632 1.33997C94.9404 1.54344 97.8722 0.856744 98.4106 1.10472C100.106 1.68332 220.068 0.894891 230.299 0.8631C236.502 0.844025 231.596 1.23824 238.895 1.07928C254.451 0.761364 288.356 1.06657 300.143 0.704142C309.576 0.405302 311.61 0.392594 320.665 0.63421C329.919 0.850392 379.12 0.538832 384.744 0.074676C386.12 -0.0524901 387.178 -0.00797814 387.178 0.138263C387.178 0.170054 421.86 0.278142 422.019 0.392591C422.279 0.538832 423.336 0.608769 424.313 0.507036C426.128 0.354437 463.044 -0.198737 463.622 0.227269C465.437 1.44806 460.351 6.64915 456.064 7.91446C451.676 9.26878 427.584 7.74915 408.218 8.56302L318.052 8.64568L298.727 9.15434C296.513 8.71561 287.997 9.82196 282.851 9.03353C280.677 8.72197 265.321 8.67745 261.93 9.00809C258.979 9.26242 180.42 8.62023 172.362 9.50403C158.003 8.76647 99.7668 9.82197 86.2648 10Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
