import React, { useEffect, useRef } from "react"
import TitleUnderline from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Chatbots con IA",
      description: "Crea chatbots con información importada o integrada de tu empresa en simples pasos. ChatGPT y WhatsApp usarán esta información para responder a tus usuarios de manera automatizada, reduciendo las tareas repetitivas de tus agentes, logrando mejorar la experiencia de tus clientes.",
      image: imageOneMobile,
      background: "#E5FAEC"
    },
    {
      title: "Envíos de mensajes masivos",
      description: "La API de WhatsApp Business te permite enviar mensajes simultáneos y de forma masiva a toda tu base de contactos. Usa el poder del marketing conversacional, enviando ofertas, notificaciones y más. Asegura que tus clientes te lean gracias al 98% de tasa de apertura del canal.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {
      title: "Mensajes interactivos",
      description: "Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica.  Añade botones y listas de mensajes cuando configures chatbots o mensajes masivos.",
      image: imageThreeMobile,
      background: "#E5FAEC"
    },
    {
      title: "Gestión entre canales",
      description: "Ya sea de teléfono o desde cualquier canal, podrás enrutar la atención de un punto de contacto a WhatsApp y viceversa. De esta forma, evitas que los usuarios tengan que repetir la información brindada al momento de cambiar de canal.",
      image: imageFourMobile,
      background: "#FCEEEE"
    },
    {
      title: "Mejor rendimiento en Ads",
      description: "Gestiona mejor los altos volúmenes de atención que provienen de las campañas de Meta Ads, Click to WhatsApp. Configura respuestas automáticas que eviten el abandono de una atención por falta de respuesta, y prospecta mejor a tus leads con las automatizaciones con IA de Beex y WhatsApp.",
      image: imageFiveMobile,
      background: "#E5FAEC"
    },
  ]

  return (
    <section className="container-experience-score">
      <p className="container-experience-score-title">
        La plataforma omnicanal de Beex ayuda a las empresas a brindar una gran <TitleUnderline underline="experiencia en"/> <TitleUnderline underline="servicio al cliente" />

      </p>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsTwo">
        {/* columna */}
        <div className="container-experience-score-cardsTwo-cards-column">
          <section className="container-experience-score-cardsTwo-card" style={{ background: "#E5FAEC" }}>
            <section>
              <p className="container-experience-score-cardsTwo-card-title">Chatbots con IA</p>
              <p className="container-experience-score-cardsTwo-card-description">
                Crea chatbots con información importada o integrada de tu empresa en simples pasos. ChatGPT y WhatsApp usarán esta información para responder a tus usuarios de manera automatizada, reduciendo las tareas repetitivas de tus agentes, logrando mejorar la experiencia de tus clientes.
              </p>
              <Link
                to={`/hablemos/${location.search}`}
                className="container-testimonies-silder-item-description-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> Conocer más sobre la historia </span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
                </svg>
              </Link>
              <br />
            </section>
            <section>
              <img
                className="container-experience-score-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsTwo-card-title">Envíos de mensajes masivos</p>
            <p className="container-experience-score-cardsTwo-card-description">
              La API de WhatsApp Business te permite enviar mensajes simultáneos y de forma masiva a toda tu base de contactos. Usa el poder del marketing conversacional, enviando ofertas, notificaciones y más. Asegura que tus clientes te lean gracias al 98% de tasa de apertura del canal.
            </p>
            <Link
              to={`/hablemos/${location.search}`}
              className="container-testimonies-silder-item-description-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span> Conocer más sobre la historia </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
              </svg>
            </Link>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#E5FAEC", padding: "32px 10px 0px" }}>
          <section style={{ padding: "0px 22px 0px" }}>
            <p className="container-experience-score-cardsTwo-card-title">Mensajes interactivos</p>
            <p className="container-experience-score-cardsTwo-card-description">
              Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica. Añade botones y listas de mensajes cuando configures chatbots o mensajes masivos.
            </p>
            <Link
              to={`/hablemos/${location.search}`}
              className="container-testimonies-silder-item-description-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span> Conocer más sobre la historia </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
              </svg>
            </Link>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-score-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsOne-card-title">Gestión entre canales</p>
            <p className="container-experience-score-cardsOne-card-description">
              Ya sea de teléfono o desde cualquier canal, podrás enrutar la atención de un punto de contacto a WhatsApp y viceversa. De esta forma, evitas que los usuarios tengan que repetir la información brindada al momento de cambiar de canal.
            </p>
            <Link
              to={`/hablemos/${location.search}`}
              className="container-testimonies-silder-item-description-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span> Conocer más sobre la historia </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
              </svg>
            </Link>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsOne-card-image"
              src={imageFourDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-score-cardsOne-column">
          {/* columna */}
          <section className="container-experience-score-cardsOne-card" style={{ background: "#E5FAEC", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-score-cardsOne-card-title">Mejor rendimiento en Ads</p>
              <p className="container-experience-score-cardsOne-card-description">
                Gestiona mejor los altos volúmenes de atención que provienen de las campañas de Meta Ads, Click to WhatsApp. Configura respuestas automáticas que eviten el abandono de una atención por falta de respuesta, y prospecta mejor a tus leads con las automatizaciones con IA de Beex y WhatsApp.
              </p>
              <Link
                to={`/hablemos/${location.search}`}
                className="container-testimonies-silder-item-description-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span> Conocer más sobre la historia </span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
                </svg>
              </Link>
              <br />
            </section>
            <section>
              <img
                className="container-experience-score-cardsOne-card-image"
                src={imageFiveDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>



      <div className="container-experience-score-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-score-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-score-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-score-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <Link
                      to={`/hablemos/${location.search}`}
                      className="container-testimonies-silder-item-description-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span> Conocer más sobre la historia </span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.27567 19C6.17418 19.0006 6.07356 18.9816 5.9796 18.9443C5.88564 18.907 5.80018 18.852 5.72812 18.7825C5.65584 18.7128 5.59846 18.6299 5.55931 18.5385C5.52016 18.4471 5.5 18.3491 5.5 18.2501C5.5 18.1511 5.52016 18.0531 5.55931 17.9617C5.59846 17.8704 5.65584 17.7874 5.72812 17.7177L12.0288 11.5913C12.4621 11.1695 12.7054 10.5977 12.7054 10.0015C12.7054 9.40538 12.4621 8.8336 12.0288 8.4118L5.72812 2.28535C5.5829 2.14415 5.50132 1.95263 5.50132 1.75294C5.50132 1.55325 5.5829 1.36173 5.72812 1.22053C5.87334 1.07933 6.0703 1 6.27567 1C6.48104 1 6.678 1.07933 6.82322 1.22053L13.1239 7.34698C13.483 7.69526 13.7679 8.10901 13.9623 8.56452C14.1567 9.02003 14.2568 9.50836 14.2568 10.0015C14.2568 10.4947 14.1567 10.983 13.9623 11.4385C13.7679 11.894 13.483 12.3078 13.1239 12.6561L6.82322 18.7825C6.75116 18.852 6.6657 18.907 6.57174 18.9443C6.47778 18.9816 6.37717 19.0006 6.27567 19Z" />
                      </svg>
                    </Link>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-score-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
